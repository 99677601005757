<template>
  <CRow class="form-group">
    <CCol sm="12">
      <label>{{formLabel}}</label>
      <ValidationProvider :rules="rules" immediate v-slot="{ errors }">

        <vue-password
          v-model="newPassword"
          autocomplete="new-password"
          :strength="score"
          type="text"
          @input="updateStrength"
        />

        <em :class="calculateClassHint(errors)">Bitte geben Sie einen gültiges Passwort ein.</em>
      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
import zxcvbn from 'zxcvbn'
import VuePassword from 'vue-password'
import VuePasswordGenerator from 'generate-password'

export default {
  name: 'FormPassword',
  components: {
    VuePassword
  },
  props: {
    formLabel: {
      default: 'Neues Passwort',
      type: String
    },
    rules: {
      default: 'required|min:6',
      type: String
    }
  },
  data () {
    return {
      score: 0,
      newPassword: ''
    }
  },
  mounted () {
    this.newPassword = VuePasswordGenerator.generate({ length: 10, numbers: true })
    this.updateStrength(this.newPassword)
  },
  watch: {
    newPassword: function () {
      this.$emit('input', this.newPassword)
    }
  },
  methods: {
    calculateClass: function (error) {
      let result = 'form-control'
      if (error.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    },
    calculateClassHint: function (error) {
      let result = 'error invalid-feedback'
      if (error.length === 0) {
        result = result + ' d-none'
      } else {
        result = result + ' d-block'
      }
      return result
    },
    updateStrength (password) {
      const result = zxcvbn(password)
      this.score = result.score
    }
  }
}
</script>
